class Updatables {
	objects = [];
	constructor(){

	}

	push(object){
		object.updateId = this.generateID();
		this.objects.push(object);
	}

	remove(id){ 
		const newList = this.objects.filter(o => o.updateId != id);
		this.objects = newList;
	}

	private generateID(){
		return Math.random().toString(16).slice(2) + Math.random().toString(16).slice(2)
	}
};

export default Updatables;