import { MainScene } from './scene';
import * as THREE from 'three';
import debounce from 'debounce';

function throttle(func, ms) {

	let isThrottled = false,
	  savedArgs,
	  savedThis;
  
	function wrapper() {
  
	  if (isThrottled) { // (2)
		savedArgs = arguments;
		savedThis = this;
		return;
	  }
  
	  func.apply(this, arguments); // (1)
  
	  isThrottled = true;
  
	  setTimeout(function() {
		isThrottled = false; // (3)
		if (savedArgs) {
		  wrapper.apply(savedThis, savedArgs);
		  savedArgs = savedThis = null;
		}
	  }, ms);
	}
  
	return wrapper;
  }

class ObjectPicker {
	raycaster;
	pointer = new THREE.Vector2();
	constructor(){
		this.raycaster = new THREE.Raycaster();
		this.raycaster.far = 100;
		console.log(this.raycaster);

		this.init();
	}

	init(){
		
		window.addEventListener('click', (e) => {
			this.setPointer(e);

			this.raycaster.setFromCamera(this.pointer, MainScene.camera);
			const intersects = this.raycaster.intersectObjects( MainScene.scene.children, true );
			for ( let i = 0; i < intersects.length; i ++ ) {
			}
		})
		const tempV = new THREE.Vector3();

		const mouseMove = (e) => {
			this.setPointer(e);
			// console.log(this.pointer);
			// console.log(this.pointer, this.pointer.x * this.pointer.y);
			// this.raycaster.setFromCamera(this.pointer, MainScene.camera);

			// const intersects = this.raycaster.intersectObjects( MainScene.scene.children, true );
			// for ( let i = 0; i < intersects.length; i ++ ) {
			// }
		};

		
		window.addEventListener('mousemove', throttle(mouseMove, 10))
	}
	
	setPointer(event){
		this.pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
		this.pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
	}

	

}

export default ObjectPicker;
export const picker = new ObjectPicker();