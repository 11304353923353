import 'regenerator-runtime/runtime';

import * as THREE from 'three';
import Game from './core/game';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
const tl = gsap.timeline();
const game = new Game();
const tabletBreakPoint = 1024;
const mobileBreakPoint = 560;

window.game = game;

window.addEventListener('DOMContentLoaded', () => {
	onDomLoad()
})

game.onloadAssets = function () {
}


function onDomLoad() {
	console.log('ondomload');
}
window.history.scrollRestoration = 'manual'
window.onbeforeunload = function () {
	window.scrollTo(0, 0);
}

