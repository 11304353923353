import * as THREE from 'three';
import config from '../configScene';

const loader = new THREE.TextureLoader();

const textures = {
	bg: loader.load('images/bg2.jpg'),
	linesMat: loader.load('assets/lines-mat1.png'),
}

// textures.bg.colorSpace = THREE.LinearSRGBColorSpace;
// textures.bg.encoding = THREE.LinearEncoding;
// console.log(textures.bg);
textures.bg.colorSpace = THREE.SRGBColorSpace;
// textures.bg.color.convertSRGBToLinear();
// textures.n.offset.x = 1
// textures.n.offset.y = -1


export default textures;
