import * as THREE from 'three';
import { assets } from '../assets/allAssetLoads';
import { clock, updatables } from './game';
import { MainScene } from './scene';
import textures from '../assets/materials';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import {GUI} from 'dat.gui';
import { isMobile } from './lib';

class Effect {
	tlMaster = gsap.timeline();
	onload;

	constructor() {
		
		this.init();
		// updatables.push(this);
	};
	
	init() {
		this.sceneInit();
		// this.debug();
	}

	sceneInit(){
		const scene = assets['assets/scene2.gltf'];
		const lettersBroken = assets['assets/letters/ep-broken.gltf'].children[0];
		scene.children[0].add(lettersBroken)

		const lettersSolid = scene.children[0].children[1];

		console.log(scene.children[0], lettersBroken);

		const lines = scene.children[0].children[0];
		const material = lettersSolid.children[0].material.clone();
		material.map = null;
		//
		// const material = new THREE.MeshBasicMaterial({});
		lettersBroken.visible = false;
		// lettersBroken.visible = true;
		this.positionBrokenLetters(lettersBroken, lettersSolid);
		const light1 = scene.children[1];
		light1.decay = 6.6;

		const tl = gsap.timeline();

		this.addBgPlane();
		MainScene.add(scene);

		this.initLetters(lettersBroken, material)
		this.initLines(lines);
		this.animateBrake(lettersBroken, lettersSolid, material);

		
		this.mouseMove(lettersSolid, lettersBroken);

		if(this.onload){
			this.onload();
		}
		
	}

	private positionBrokenLetters(lettersBroken, lettersSolid){
		const rootBroken = lettersBroken
		const rootSolid = lettersSolid
		// console.log(assets['assets/scene2.gltf']);
		// console.log(rootSolid, rootBroken);
		const deg = Math.PI * 2 / 360;
		rootBroken.position.set(-0.042,0.019,0.026)
		rootBroken.rotation.set(deg * 1, deg * 27.00, deg * 4.40)
		// root.rotation.set(1.3080, 0.1363, 0.0384)

		// rootBroken.position.copy(rootSolid.position);
		// rootBroken.rotation.copy(rootSolid.rotation);
	}

	debug(){
		// MainScene.scene.add(new THREE.AxesHelper())
		// MainScene.scene.add(new THREE.GridHelper())
		const TAU = Math.PI * 2;

		const gui = new GUI({
			step: 0.01,
		});
		document.querySelector('.gui-wrap')?.appendChild(gui.domElement);
		// gui.domElement(document.querySelector('.gui-wrap'))
		console.log(gui);





	}

	private addBgPlane(){
		const bgPlane = new THREE.Mesh( 
			new THREE.PlaneGeometry( 2.4, 1.4 ),
			new THREE.MeshBasicMaterial( {
				map: textures.bg,
				side: THREE.DoubleSide,
			},
		));
		const deg = Math.PI * 2 / 360;
		bgPlane.position.z = -1.65;
		bgPlane.rotation.x = deg * -7.04;
		bgPlane.rotation.y = deg * 5.96; 
		bgPlane.rotation.z = deg * 0.73; 

		MainScene.add(bgPlane);
	}

	private initLetters(letters, mat): void{
		const meshes = letters.children;

		meshes.forEach(m => {
			m.material = mat;
		});
	}

	private initLines(lines): void{
		const tl = gsap.timeline({
			repeat: -1,
			yoyo: true,
		});

		tl.to(lines.rotation, {
			z: Math.PI * 2 * .015,
			duration: 10,
			delay: .3,
			ease: 'none',
		}, '<')

		console.log(lines);
		
		const material = new THREE.MeshMatcapMaterial({
			matcap: textures.linesMat,
		})

		//
		console.log(textures.linesMat.source);
		lines.children.forEach(l => {
			l.material.dispose();
			l.material = material;
		})
	}

	private mouseMove(lettersSolid, lettersBroken){
		// MainScene.camera()
		const winW = window.innerWidth;
		const winH = window.innerHeight;
		const STR = .35;
		const originX = lettersSolid.rotation.x;
		const originY = lettersSolid.rotation.y;
		const originZ = lettersSolid.rotation.z;
		const originX2 = lettersBroken.rotation.x;
		const originY2 = lettersBroken.rotation.y;
		const originZ2 = lettersBroken.rotation.z;

		
		if(isMobile()){
		} else {
			window.addEventListener('mousemove', (e) => {
				const x = e.clientX / winW - .5;
				const y = e.clientY / winH - .5;
	
				lettersSolid.rotation.x = originX + (x * STR);
				lettersSolid.rotation.z = originY + (y * STR * 2);

				lettersBroken.rotation.x = originX2 + (x * STR);
				lettersBroken.rotation.z = originY2 + (y * STR * 2);
			} )
		}
	}

	private animateBrake(lettersBroken, lettersSolid, material){
		lettersBroken.visible = false;
		// lettersSolid.visible = false;

		let brokeFirstTime = false;

		const meshes = lettersBroken.children;
		const scrollTl = gsap.timeline({
			// repeat: -1,
			// repeatDelay: 1,
			// delay: 1,
			// yoyo: true,
			immediateRender: false,
		});
		const scrollTl2 = gsap.timeline({
			immediateRender: false,
			onComplete: function() {
				console.log('compete');
				lettersSolid.visible = true;
				lettersBroken.visible = false;
				// document.querySelector('body')?.classList.add('h-auto');
			},
			onStart: function () {
				showHtml();
			},
			// onUpdate: function(t) {
			// 	console.log('object');
			// 	// console.log(scrollTl2);
			// 	// if(t.progress >= .9){
			// 	// }
			// },
		});
		const htmlTl = gsap.timeline();

		const TAU = Math.PI * 2; 
		const startPositions: any[] = [];
		const startRotations: any[] = [];
		meshes.forEach((m, i) => {
			startPositions.push([m.position.x, m.position.y, m.position.z]);
			startRotations.push([m.rotation.x, m.rotation.y, m.rotation.z]);
			scrollTl.to(m.position, {
				x: (Math.random() - .5) * 1.5,
				y: (Math.random() - .5) * 1.5,
				z: (Math.random() - .5) * 1.5,
				ease: 'Power2.easeOut',
				duration: 3,
			}, '<')
			scrollTl.to(m.rotation, {
				x: (Math.random() - .5) * TAU * 2,
				y: (Math.random() - .5) * TAU * 2,
				z: (Math.random() - .5) * TAU * 2,
				ease: 'Power2.easeOut',
				duration: 3,
			}, '<')
			
			scrollTl2.to(m.position, {
				x: startPositions[i][0],
				y: startPositions[i][1],
				z: startPositions[i][2],
				ease: 'Power2.easeOut',
				duration: 3,
			}, '<')
			scrollTl2.to(m.rotation, {
				x: startRotations[i][0],
				y: startRotations[i][1],
				z: startRotations[i][2],
				ease: 'Power2.easeOut',
				duration: 3,
			}, '<')
		});

		const showHtml = () => {
			document.body?.classList.add('showHtml');
			document.querySelector('.scroll-area')?.classList.add('no-scroll');
			document.querySelector('body')?.classList.remove('no-scroll');
			document.querySelector('.wrapper')?.classList.add('wrapper--show');
			
			document.querySelector('.header')?.classList.add('show');
			document.querySelector('.hero__wrap')?.classList.add('show');
			
		}
		
		ScrollTrigger.create({
			trigger: '.scroll-area',

			scroller: document.querySelector('.scroll-area'),
			start:"top",
			// srollStart: 'top',
			// markers: true,
			scrub: true,
			toggleActions:"play none none reverse",
			animation: scrollTl,
			onEnter: () => {
				console.log('onEnter');
				lettersSolid.visible = false
				lettersBroken.visible = true
			},
			onEnterBack: () => {
				console.log('onEnterBack');
			},
			onLeave: () => {
				console.log('onLeave');
				scrollTl.kill();
			},
			onLeaveBack: () => {
				lettersSolid.visible = true;
				lettersBroken.visible = false;
			},
			// onUpdate: (t) => {
			// 	// console.log(t);
			// 	if((t.progress >= .5) && (!brokeFirstTime)){
			// 		console.log('broke!!!!');
			// 		brokeFirstTime = true;

			// 		showHtml();
			// 	}
			// }
		})

		ScrollTrigger.create({
			trigger: '.scroll-area__letters-up',
			scroller: document.querySelector('.scroll-area'),
			start:"top",
			toggleActions:"play none none reverse",
			animation: scrollTl2,
			immediateRender: false,
			onEnter: () => {
				console.log('onEnter letters-up');
			},
			
		})

	}

	update() {
	}
}

export default Effect;