import Scene, { MainScene } from './scene';
import { picker } from './objectPicker';
import * as THREE from 'three';
import {assetLoader} from './assetLoader';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import dat from 'dat.gui';
import config from '../configScene';
import Controls from './controls';
import textures from '../assets/materials';
import gsap from 'gsap';
import { assets as Allassets} from '../assets/allAssetLoads';
import ObjectSwitcher from './objectSwitcher';
import Stats from 'three/examples/jsm/libs/stats.module';
import Updatables from './updatables';
import Effect from './Effect';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { BloomPass } from 'three/examples/jsm/postprocessing/BloomPass';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass';
import { FXAAShader } from 'three-stdlib/shaders/FXAAShader';
import { SSRPass } from 'three-stdlib/postprocessing/SSRPass';
import { RGBShiftShader } from 'three-stdlib/shaders/RGBShiftShader';

import { ShaderPass } from 'three-stdlib/postprocessing/ShaderPass';
import { LuminosityShader } from 'three-stdlib';
import { GammaCorrectionShader } from 'three-stdlib/shaders/GammaCorrectionShader';

class Game {
	MainScene = MainScene;
	delta = 0;
	clock = clock;
	interval = .016; // fps
	controls;
	picker = picker;
	assetLoader = assetLoader;
	composer;
	effect;
	onloadAssets;
	stats;

	constructor(){
		// document.body.appendChild( this.stats.dom );
		this.run();
		this._init();
		// this.composeEffects();
	}
	
	_init(){
		// const controls = new OrbitControls(MainScene.camera, document.body);
		// controls.update();
		console.log(this.MainScene);

		this.assetLoader.callback = (assets) => {
			Allassets = assets;
			if(this.onloadAssets){
				this.onloadAssets();
			}
			
			this.onAssetsLoad(assets);	
		}
	}

	private composeEffects(){
		MainScene.renderer.toneMappingExposure = 5.69;
		this.composer = new EffectComposer(MainScene.renderer);
		const renderPass = new RenderPass( MainScene.scene, MainScene.camera );
		this.composer.addPass( renderPass );
		const outputPass = new OutputPass();
		const bloomPass = new BloomPass();
		
		const bokehPass = new BokehPass(MainScene.scene, MainScene.camera, {
			focus: 0.0,
			aperture: 0.00025,
			maxblur: 0.001
		});

		const rgbshiftPass = new ShaderPass(RGBShiftShader);
		rgbshiftPass.uniforms[ 'amount' ].value = 0.00045;
		// this.composer.addPass( bloomPass );
		// this.composer.addPass( rgbshiftPass );
		// this.composer.addPass( new ShaderPass( GammaCorrectionShader ) );
		// this.composer.addPass( outputPass );
	}
	
	private onAssetsLoad(assets){
		console.log('LOAD ASSETS');

		this.stats = new Stats();
		document.body.appendChild( this.stats.dom );
		this.stats.begin();
		this.effect = new Effect();
	}

	

	run(){
		requestAnimationFrame(() => this.run());
		this.delta += this.clock.getDelta();
		if (this.delta > this.interval) {
			this.delta = this.delta % this.interval;
			this.MainScene.getRenderer().render(this.MainScene.getScene(), this.MainScene.getCamera());
			// this.composer.render();
			this.update()
		}
		
	}

	update(){
		if(this.stats){
			this.stats.update()
		}
		for (let i = 0; i < updatables.objects.length; i++) {
			updatables.objects[i].update();
		}
	}
	
}

export const clock = new THREE.Clock();
export let updatables = new Updatables();
export default Game;