import * as THREE from 'three';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader';
import config from '../configScene';


class AssetLoader {
	sources: string[];
	callback: (assets) => void;
	assets = {};
	private count = 0;
	constructor(settings, callback?){
		this.sources = settings.sources;
		this.callback = callback;
		
		this.init();
	}
	
	init(){
		const manager = new THREE.LoadingManager();
		const loader = new GLTFLoader(manager)
		// const dracoLoader = new DRACOLoader();
		// dracoLoader.setDecoderPath( 'libs/draco/' );
		// loader.setDRACOLoader( dracoLoader );

		if(!Object.keys(this.sources).length){
			console.error('0 assets to load')
			return
		}


		Object.values(this.sources).map((src, i) => {
			loader.load(src, (gltf) => {
				const mesh = gltf.scene;
				mesh.assetName = Object.keys(this.sources)[i];
				this.assets.src
				this.assets[src] = mesh;
			}, (xhr) => {
				// console.log(xhr);
			}, (err) => {
				console.error(`failed to load asset: "${src}"`,err);
			})
		})

		manager.onLoad = ( ) => {
			if(this.callback){
				this.callback(this.assets);
			}
		};

	}
}

export default AssetLoader;
export const assetLoader = new AssetLoader({
	sources: config.assets,
});

