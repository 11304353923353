import * as THREE from 'three';
import textures from '../assets/materials';

class Scene {
	scene: THREE.Scene = new THREE.Scene();
	camera: THREE.PerspectiveCamera = new THREE.PerspectiveCamera(22.32, window.innerWidth / window.innerHeight, .1, 10 );
	renderer: THREE.WebGLRenderer = new THREE.WebGLRenderer({
		antialias: true,
		alpha: true,
		// minFilter: THREE.LinearFilter, 
		// magFilter: THREE.LinearFilter, 
		// format: THREE.RGBAFormat, 
		// stencilBuffer: false
	});
	//
	clock = new THREE.Clock();
	delta = 0;
	interval = 1 / 10; // fps
	//
	constructor() {
		this._init();
		this.start();
	}

	private _init() {
		
		console.log(this.renderer);
		this.renderer.useLegacyLights = false;
		this.renderer.setSize(document.documentElement.clientWidth, document.documentElement.clientHeight);
		// this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
		// this.renderer.setPixelRatio(1.8);

		const TAU = Math.PI * 2;

		this.camera.position.set(0.170, .311, 1.904)
		this.camera.rotation.set(TAU / 360 * -9.27, TAU / 360 * 5.04, TAU / 360 * .47)
		
		this.renderer.outputColorSpace = THREE.SRGBColorSpace;
		this.renderer.setClearColor( 0x000000,  0);
		document.querySelector('.canvas-wrap')!.appendChild(this.renderer.domElement);
		//
		const resize = this.onWindowResize.bind(this)
		this.onWindowResize();
		window.addEventListener('resize', resize );
		console.log('init scene')
	}

	getScene() {
		return this.scene;
	}
	getCamera() {
		return this.camera;
	}
	getRenderer() {
		return this.renderer;
	}
	onWindowResize() {
		const w = document.documentElement.clientWidth;
		const h = document.documentElement.clientHeight;
		this.camera.aspect = w / h;
		this.renderer.setSize( w, h );
		
		if(window.innerWidth <= 1024){
			const W = window.innerWidth;
			const H = 800;
			this.camera.aspect = W / H;
			this.renderer.setSize( W, H );
			this.camera.position.set(0.170, .341, 1.904)
			// this.scene.scale.setScalar(.75);
			// this.scene.scale.setScalar(.85);
			this.scene.scale.setScalar(.65);
			this.scene.position.y = .1;
		}
		
		this.camera.updateProjectionMatrix();
	}
	add(object3d){
		this.scene.add(object3d);
	}

	coverBg () {
		
	} 

	start() {
		// this.update()
	}

	update() {
		
	}
}

export default Scene;
export const MainScene = new Scene(); 